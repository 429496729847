import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';
import FingerprintJSPro, { Agent as ProAgent } from '@fingerprintjs/fingerprintjs-pro';

export type FingerprintJsProOptions = {
	apiKey: string;
	endpoint?: string;
};

export default abstract class FingerprintService {
	public abstract getFingerprintId(): Promise<string>;
}

export class FingerprintJsService extends FingerprintService {
	private fpjs: Promise<Agent>;

	constructor() {
		super();
		this.fpjs = FingerprintJS.load({});
	}
	public async getFingerprintId(): Promise<string> {
		const agent = await this.fpjs;
		const result = await agent.get();
		const visitorId = FingerprintJS.hashComponents(result.components);
		return visitorId;
	}
}

export class FingerprintJsProService extends FingerprintService {
	private fpjs: Promise<ProAgent>;

	constructor(private readonly _options: FingerprintJsProOptions) {
		super();
		this.fpjs = FingerprintJSPro.load({
			apiKey: _options.apiKey,
			endpoint: _options.endpoint,
			scriptUrlPattern: _options.endpoint
				? `${_options.endpoint}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`
				: undefined,
		});
	}

	public async getFingerprintId(): Promise<string> {
		const agent = await this.fpjs;
		const result = await agent.get();
		return result.visitorId;
	}
}
