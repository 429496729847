'use client';

import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import NotAuthenticated from './NotAuthenticated';
import useAuthenticatedUser from '../../Hooks/useAuthenticatedUser';
import Loading from '../Misc/Loading';

export type RequireAuthenticatedContextValue = {
	loginLink?: ReactNode;
};

export const RequireAuthenticatedContext = createContext<RequireAuthenticatedContextValue>({});

export type RequireAuthenticatedProviderProps = {
	children: ReactNode;
} & RequireAuthenticatedContextValue;

export function RequireAuthenticatedProvider(props: RequireAuthenticatedProviderProps) {
	const { children, loginLink } = props;

	const value: RequireAuthenticatedContextValue = {
		loginLink,
	};

	return <RequireAuthenticatedContext.Provider value={value}>{children}</RequireAuthenticatedContext.Provider>;
}

export type RequireAuthenticatedProps = {
	children?: ReactNode;
	notAuthenticatedChildren?: ReactNode;
	loadingChildren?: ReactNode;
};

export default function RequireAuthenticated(props: RequireAuthenticatedProps) {
	const { children, notAuthenticatedChildren, loadingChildren } = props;

	const requireAuthenticationContextValue = useContext(RequireAuthenticatedContext);

	useEffect(() => {
		// A no-op to force nextjs to render this component on the clientside
	}, []);

	const { user, isInitialLoading } = useAuthenticatedUser();

	if (isInitialLoading) {
		return loadingChildren ?? <Loading />;
	} else if (user) {
		return <>{children}</>;
	} else {
		return (
			notAuthenticatedChildren ?? (
				<NotAuthenticated>{requireAuthenticationContextValue.loginLink}</NotAuthenticated>
			)
		);
	}
}
