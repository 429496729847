import React, { ReactNode } from 'react';

export type NotAuthenticatedProps = {
	className?: string;
	children?: ReactNode;
};

export default function NotAuthenticated(props: NotAuthenticatedProps) {
	const { className, children } = props;

	return (
		<div className={`NotAuthenticated ${className ?? ''}`}>
			<h1>Not Authenticated</h1>
			<p>Sorry, you must be logged in to access to this area.</p>
			{children}
		</div>
	);
}
