'use client';

import { useMemo } from 'react';
import Hotjar from '@hotjar/browser';
import { useHotjarProvider } from '../Components/Hotjar/HotjarProvider';

export type HotjarOptions = {
	siteId: number;
	version?: number;
	debug?: boolean;
};

export default class HotjarService {
	public constructor(options: HotjarOptions) {
		if (typeof window !== 'undefined') {
			Hotjar.init(options.siteId, options.version ?? 6, {
				debug: options.debug ?? false,
			});
		}
	}

	public sendEvent(eventName: string) {
		if (typeof window !== 'undefined') {
			Hotjar.event(eventName);
		}
	}

	// See https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#user-attribute-values
	public identify<T extends Record<string, string | number | Date | boolean | undefined | null>>(
		userId: string | null,
		data: T,
	) {
		if (typeof window !== 'undefined') {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			Hotjar.identify(userId, data as any);
		}
	}
}

export function useHotjarService() {
	const options = useHotjarProvider();
	const hotjarService = useMemo(() => new HotjarService(options), [options]);
	return hotjarService;
}
