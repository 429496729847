'use client';

import React, { ReactNode, createContext } from 'react';

export const SessionContext = createContext<object | null>(null);

export type SessionProviderProps<T extends object> = {
	sessionData: T | null;
	children?: ReactNode;
};

export default function SessionProvider<T extends object>(props: SessionProviderProps<T>) {
	const { sessionData, children } = props;

	return <SessionContext.Provider value={sessionData}>{children}</SessionContext.Provider>;
}

export function useSessionContext<T extends object>() {
	const context = React.useContext(SessionContext);
	return context as T | null;
}
