'use client';

import React, { ReactNode, createContext } from 'react';
import { HotjarOptions } from '../../Services/HotjarService';

export const HotjarProviderContext = createContext<HotjarOptions | null>(null);

export type HotjarProviderProps = {
	children?: ReactNode;
	options: HotjarOptions;
};

export default function HotjarProvider(props: HotjarProviderProps) {
	const { children, options } = props;

	return <HotjarProviderContext.Provider value={options}>{children}</HotjarProviderContext.Provider>;
}

export function useHotjarProvider() {
	const context = React.useContext(HotjarProviderContext);
	if (!context) {
		throw new Error('useHotjarProvider must be used within a HotjarProvider');
	}
	return context;
}
